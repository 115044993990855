import { DropdownItem } from "@sebgroup/react-components/dist/Dropdown/Dropdown";
import { ModalProps } from "@sebgroup/react-components/dist/Modal/Modal";
import { Column } from "@sebgroup/react-components/dist/Table/Table";
import { max } from "d3";
import { DashboardPropertiesOptions } from "../components/home/dashboardItem/modals/AddDashboardItem";

export const RECEIVE_AUTH_USER = "RECEIVE_AUTH_USER";
export type RECEIVE_AUTH_USER = typeof RECEIVE_AUTH_USER;
export const LOG_AUTH_ERROR = "LOG_AUTH_ERROR";
export type LOG_AUTH_ERROR = typeof LOG_AUTH_ERROR;
export const REQUEST_AUTH_USER = "REQUEST_AUTH_USER";
export type REQUEST_AUTH_USER = typeof REQUEST_AUTH_USER;
export const SIGNOUT_USER = "SIGNOUT_USER";
export type SIGNOUT_USER = typeof SIGNOUT_USER;

export const SET_DATA_REFRESH_TIME = "SET_DATA_REFRESH_TIME";
export type SET_DATA_REFRESH_TIME = typeof SET_DATA_REFRESH_TIME;

export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export type TOGGLE_NOTIFICATION = typeof TOGGLE_NOTIFICATION;

export const RECEIVE_GROUPS = "RECEIVE_GROUPS";
export const LOG_GROUP_ERROR = "LOG_GROUP_ERROR";

export const RECEIVE_DEVICES = "RECEIVE_DEVICES";
export const RESET_DEVICE = "RESET_DEVICE";
export type RESET_DEVICE = typeof RESET_DEVICE;
export const CLEAR_GROUPS = "CLEAR_GROUPS";
export type CLEAR_GROUPS = typeof CLEAR_GROUPS;
export const RESET = "RESET";
export const RECEIVE_DEVICE_IDENTITY = "RECEIVE_DEVICE_IDENTITY";
export const CLEAR_DEVICE_IDENTITY = "CLEAR_DEVICE_IDENTITY";
export type CLEAR_DEVICE_IDENTITY = typeof CLEAR_DEVICE_IDENTITY;
export const RECEIVE_COLLECTIONS = "RECEIVE_COLLECTIONS";
export const CLEAR_COLLECTIONS = "CLEAR_COLLECTIONS";
export type CLEAR_COLLECTIONS = typeof CLEAR_COLLECTIONS;
export const RECEIVE_ITEMS = "RECEIVE_ITEMS";
export const CLEAR_ITEMS = "CLEAR_ITEMS";
export type CLEAR_ITEMS = typeof CLEAR_ITEMS;
export const RECEIVE_COLLECTION_ITEMS = "RECEIVE_COLLECTION_ITEMS";
export const RECEIVE_EVENT_ITEMS = "RECEIVE_EVENT_ITEMS";
export const RECEIVE_EVENT_TRIGGER = "RECEIVE_EVENT_TRIGGER";
export const CLEAR_COLLECTION_ITEMS = "CLEAR_COLLECTION_ITEMS";
export type CLEAR_COLLECTION_ITEMS = typeof CLEAR_COLLECTION_ITEMS;
export const RECIEVE_EVENT_SIZE = "RECIEVE_EVENT_SIZE";
export const RECIEVE_ASSET_SIZE = "RECIEVE_ASSET_SIZE";
export const RECIEVE_TRIGGER_SIZE = "RECIEVE_TRIGGER_SIZE";
export const VERSION = "VERSION";
export const RECIEVE_DATAVALUE_SIZE = "RECIEVE_DATAVALUE_SIZE";
export const LOG_DEVICE_ERROR = "LOG_GROUP_ERROR";

export const SENSORSTYPESCOLUMN: Array<Column> = [
  {
    label: "id",
    accessor: "id",
    isHidden: true,
  },
  {
    label: "deviceId",
    accessor: "deviceId",
    isHidden: true,
  },
  {
    label: "Type",
    accessor: "dataType",
    isHidden: true,
  },
  {
    label: "Type",
    accessor: "sensorDataType",
  },
  {
    label: "Name",
    accessor: "name",
  },
  {
    label: "Compensation Value",
    accessor: "compensationValue",
  },
];

export const DASHBOARDDATASOURCECOLUMN: Array<Column> = [
  {
    label: "Device",
    accessor: "device",
  },
  {
    label: "Device Source",
    accessor: "deviceSource"
  },
  {
    label: "Sensor",
    accessor: "sensor",
  },
  {
    label: "Label",
    accessor: "label",
  },
  {
    label: "Color",
    accessor: "color",
  },
];

export const ACTUATORCOLUMNS: Array<Column> = [
  {
    label: "id",
    accessor: "id",
    isHidden: true,
  },
  {
    label: "deviceId",
    accessor: "deviceId",
    isHidden: true,
  },
  {
    label: "accountId",
    accessor: "accountId",
    isHidden: true,
  },
  {
    label: "Name",
    accessor: "name",
  },
  {
    label: "Type",
    accessor: "actuatorType",
  },
  {
    label: "ON",
    accessor: "ON",
  },
  {
    label: "OFF",
    accessor: "OFF",
  },
  {
    label: "Value",
    accessor: "value",
  },
  {
    label: "Message",
    accessor: "message",
  },
];

export const ATTRIBUTESCOLUMNS: Array<Column> = [
  {
    label: "id",
    accessor: "id",
    isHidden: true,
  },
  {
    label: "deviceId",
    accessor: "deviceId",
    isHidden: true,
  },
  {
    label: "accountId",
    accessor: "accountId",
    isHidden: true,
  },
  {
    label: "Name",
    accessor: "name",
  },
  {
    label: "Data Type",
    accessor: "type",
  },
  {
    label: "Current Value",
    accessor: "currentValue",
  },
  {
    label: "Previous Value",
    accessor: "lastValue",
  },
  {
    label: "Last Modified",
    accessor: "lastUpdateDate",
  }
];

export const ACTUATORS = [
  {
    label: "Digital",
    value: 0,
  },
  {
    label: "Analog",
    value: 1,
  },
  {
    label: "Message",
    value: 2,
  },
];

export const PROPERTIESCOLUMNS: Array<Column> = [
  {
    label: "id",
    accessor: "id",
    isHidden: true,
  },
  {
    label: "Property name",
    accessor: "propertyName",
    isHidden: true,
  },
  {
    label: "Property",
    accessor: "propertyLabel",
  },
  {
    label: "Custom label",
    accessor: "otherPropertyLabel",
    isHidden: true,
  },
  {
    label: "Value",
    accessor: "propertyValue",
  },
];

export const DASHBOARDITEMTYPES = [
  {
    label: "Simple Text",
    value: 9
  },
  {
    label: "Environment Aggregate",
    value: 12
  },
  {
    label: "Device Aggregate",
    value: 13
  },
  {
    label: "Event Viewer",
    value: 14
  },
  {
    label: "Environment Map",
    value: 15
  },
  {
    label: "Indicator",
    value: 10
  },
  {
    label: "Environment Widget",
    value: 11
  },
  {
    label: "Tank",
    value: 0,
  },
  {
    label: "Gauge",
    value: 1,
  },
  {
    label: "ColoredGuage",
    value: 8,
  },
  {
    label: "Bar graph",
    value: 2,
  },
  {
    label: "Line graph",
    value: 3,
  },
  {
    label: "Doughnut graph",
    value: 4,
  },
  {
    label: "PieChart graph",
    value: 5,
  },
  {
    label: "Multiline graph",
    value: 7,
  },
  {
    label: "other",
    value: 6,
  }
];

export const HTTPREQUESTMETHODS: Array<DropdownItem> = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "PATCH",
    value: "PATCH",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
  {
    label: "HEAD",
    value: "HEAD",
  },
  {
    label: "OPTIONS",
    value: "OPTIONS",
  },
  {
    label: "TRACE",
    value: "TRACE",
  },
  {
    label: "CONNECT",
    value: "CONNECT",
  },
];

export const TIMERULEOPERATORS: Array<DropdownItem> = [
  {
    label: "Last received is >",
    value: ">",
  },
  {
    label: "Last received is <",
    value: "<",
  },
  {
    label: "Last received is =",
    value: "=",
  },
  {
    label: "Last received is <=",
    value: "<=",
  },
  {
    label: "Last received is >=",
    value: ">=",
  },
];

export const STRINGRULEOPRATORS: Array<DropdownItem> = [
  {
    label: "Contains",
    value: "contains",
  },
  {
    label: "Start with",
    value: "startWith",
  },
  {
    label: "End with",
    value: "endWith",
  },
  {
    label: "Length",
    value: "length",
  },
  {
    label: "Equals",
    value: "equals",
  },
  {
    label: "Not Equal",
    value: "notequal",
  },
];

export const NUMBERRULEOPERATORS: Array<DropdownItem> = [
  {
    label: ">",
    value: ">",
  },
  {
    label: ">=",
    value: ">=",
  },
  {
    label: "<",
    value: "<",
  },
  {
    label: "<=",
    value: "<=",
  },
  {
    label: "=",
    value: "=",
  },
  {
    label: "!=",
    value: "!=",
  },
];

export const COLLECTIONRULESOPERATOR: Array<DropdownItem> = [
  {
    label: "Exist in value",
    value: "in-value",
  },
  {
    label: "Not in value",
    value: "not-in-value",
  },
  {
    label: "Exist in key",
    value: "in-key",
  },
  {
    label: "Not in key",
    value: "not-in-key",
  }
];

export const ACTIONTYPES: Array<DropdownItem> = [
  
  {
    label: "All",
    value: '',
  },{
    label: "Rest service action",
    value: 0,
  },
  {
    label: "Mqqt published action",
    value: 1,
  },
  {
    label: "Email",
    value: 2,
  },
  {
    label: "SMS" || "Telegram",
    value: 3,
  },
  {
    label: "Actuator",
    value: 4,
  },
  {
    label: "Set Attribute",
    value: 5,
  },
  {
    label: "Notification",
    value: 6,
  },
];

export const ACTIONTYPESFRONTSEARCH: Array<DropdownItem> = [
  {
    label: "Rest service action",
    value: 'Rest service action',
  },
  {
    label: "Mqqt published action",
    value: 'Mqqt published action',
  },
  {
    label: "Email",
    value: 'Email',
  },
  {
    label: "SMS",
    value: 'SMS',
  },
  {
    label: "Actuator",
    value: 'Actuator',
  },
  {
    label: "Set Attribute",
    value: 'Set Attribute',
  },
  {
    label: "Notification",
    value: 'Notification',
  },
];



export enum ChartType {
  Tank = 0,
  Gauge = 1,
  BarGraph = 2,
  LineGraph = 3,
  Doughnut = 4,
  PieChart = 5,
  Other = 6,
  Multiline=7,
  ColoredGuage=8,
  SimpleTextWidget=9,
  GateWidget=10,
  EnvironmentWidget=11,
  EnvironmentAggregate=12,
  DeviceAggregate=13,
  EventViewer=14,
  EnvironmentMapWidget=15,

}

export enum DashboardItemStatus {
  On = 1,
  Off = 0,
}
export const DASHBOARDPROPERTIES: Array<DashboardPropertiesOptions> = [
  {
    type: ChartType.Tank,
    properties: [
      {
        label: "Capacity",
        value: "capacity",
      },
      {
        label: "Current Value",
        value: "value",
      },
      {
        label: "Refresh rate",
        value: "refreshRate",
      },
    ],
  },
  {
    type: ChartType.Gauge,
    properties: [
      {
        label: "Minimum value",
        value: "minimumValue",
      },
      {
        label: "Maximum value",
        value: "maximumValue",
      },
    ],
  },
  {
    type: ChartType.BarGraph,
    properties: [
      {
        label: "X-axis label",
        value: "x-axis",
      },
      {
        label: "Y-axis label",
        value: "y-axis",
      },
    ],
  },
  {
    type: ChartType.LineGraph,
    properties: [
      {
        label: "X-axis label",
        value: "x-axis",
      },
      {
        label: "Y-axis label",
        value: "y-axis",
      },
    ],
  },
  {
    type: ChartType.Doughnut,
    properties: [
      {
        label: "Value",
        value: "value",
      },
      {
        label: "Name",
        value: "name",
      },
    ],
  },
  {
    type: ChartType.PieChart,
    properties: [
      {
        label: "Value",
        value: "value",
      },
      {
        label: "Name",
        value: "name",
      },
    ],
  },
  {
    type: 4,
    properties: [
      {
        label: "Other",
        value: "other",
      },
    ],
  },
];

export const SENSORSTYPES = [
  {
    label: "decimal",
    value: 0,
  },
  {
    label: "String",
    value: 1,
  },
  {
    label: "int",
    value: 2,
  },
  {
    label: "double",
    value: 3,
  },
  {
    label: "Number",
    value: 4,
  },
  {
    label: "NumberRange",
    value: 5,
  },
  {
    label: "RawText",
    value: 6,
  },
  {
    label: "Json",
    value: 7,
  },
  {
    label: "Location",
    value: 8,
  },{
    label: "Image",
    value: 9,
  },
];


export const DURATION = [
  {
    label: "Secs",
    value: 'second',
  },
  {
    label: "Mins",
    value: 'minute',
  },
  {
    label: "Hrs",
    value: 'hour',
  }
];


export const AUTOMATIONACTIONTYPES = [
  {
    label: "SMS",
    value: 'sms',
  },
  {
    label: "EMAIL",
    value: 'email',
  },
  {
    label: "TELEGRAM",
    value: 'telegram',
  },
  {
    label: "Attribute",
    value: 'attribute',
  }, 
  {
    label: "Rest service action",
    value: 'rest service action',
  },
  {
    label: "Mqqt published action",
    value: 'mqqt published action',
  },
  {
    label: "Actuator",
    value: 'actuator',
  }
];

export const AUTOMATIONTYPES = [
  {
    label: "Threshold",
    value: 'Threshold',
  }, 
  // {
  //   label: "Numeric Monitoring",
  //   value: 'NumericMonitoring',
  // },
  // {
  //   label: "Text Monitoring",
  //   value: 'TextMonitoring',
  // }
];

export const RecipientType = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Group",
    value: 1,
  },
  {
    label: "User",
    value: 2,
  },
  {
    label: "Account Owner",
    value: 3,
  }
];

export const NotificationPriority = [
  {
    label: "Select",
    value: null,
  },
  {
    label: "Normal",
    value: "normal",
  },
  {
    label: "High",
    value: "high",
  }
];

export const Severity =  [
    {
      label: "Select",
      value: null,
    },
    {
      label: "Info",
      value: "Info",
    },
    {
      label: "Warning",
      value: "Warning",
    },
    {
      label: "Critical",
      value: "Critical",
    },
  ];

export const DEVICETYPES = [
  {
    label: "Please Select",
    value: '',
  },
  {
    label: "class A end Node",
    value: 'ClassAEndNode',
  },
  {
    label: "Class B end Node",
    value: 'ClassBEndNode',
  },
  {
    label: "Class C end Node",
    value: 'ClassCEndNode',
  },
  {
    label: "Environment",
    value: 'Environment',
  },
  {
    label: "Gateway",
    value: 'Gateway',
  },
  {
    label: "Pvol",
    value: 'Pvol',
  },
  {
    label: "Beacon Station",
    value: 'BeaconStation',
  },
];

export const initialState: ModalProps = {
  toggle: false,
  fullscreen: false,
  position: null,
  centered: false,
  size: 'modal-lg',
  disableBackdropDismiss: true,
  onDismiss: null,
};
